
import { defineComponent } from 'vue'
import { ProjectDto } from '@/api/dto'
import { getSignTopNum } from '@/api/sign'
import { getSignProjectList } from '@/api/project'
import {
    getSignEnterpriseList,
    EnterpriseDto,
    getSignAloneList
} from '@/api/enterprise'
import { dateFormat } from '@/utils/util'
import { shortcuts } from '@/utils/global'
import { ElMessage } from 'element-plus'
export default defineComponent({
    name: 'Sign',
    data () {
        return {
            active: '全部',
            projectTotal: 0,
            enterpriseaTotal: 0,
            projectPageSize: 10,
            projectPageNum: 1,
            searchProjectText: '',
            enterpriseaPageSize: 10,
            enterpriseaPageNum: 1,
            searchEnterpriseaText: '',
            tableProjectData: [] as ProjectDto[],
            tableEnterpriseData: [] as EnterpriseDto[],
            peopleCount: 0,
            projectCount: 0,
            // new Date().toLocaleDateString().split('/').join('-').split('-')[0] + '-' + new Date().toLocaleDateString().split('/').join('-').split('-')[1] + '-01',
            defaultStart: dateFormat('YY-mm', new Date()) + '-01',
            // new Date().toLocaleDateString().split('/').join('-'),
            defaultEnd: dateFormat('YY-mm-dd', new Date()),
            beginCreateTime: '', // 开始日期
            endCreateTime: '', // 结束日期
            shortcuts: shortcuts.slice(0, 3),
            isEnterpriseProjectType: 'all',
            projectTime: '',
            enterpriseTime: '',
            alonePageNum: 1,
            alonePageSize: 10,
            aloneList: [] as EnterpriseDto[],
            aloneTotal: 0,
            serveCompanyCount: 0,
            servePeopleCount: 0
        }
    },
    async created () {
        getSignTopNum({
            queryType: 0,
            beginCreateTime: this.defaultStart,
            endCreateTime: this.defaultEnd
        }).then(res => {
            this.peopleCount = res.peopleCount
            this.projectCount = res.projectCount
        })
        // await this.companyHandleChange()
        await this.projectHandleChange()
        // await this.getAloneQuery()
    },
    methods: {
        async companyHandleChange () {
            // if (!this.projectTime) {
            //     ElMessage({ message: '请选择时间', type: 'warning' })
            //     return
            // }
            var resEnd = new Date().toLocaleDateString().split('/').join('-') // 只包含日期
            console.log(resEnd, '只包含日期')
            var resStart = resEnd.split('-')[0] + '-' + resEnd.split('-')[1] + '-01'
            console.log(resStart, '只包含日期')
            const result = await getSignEnterpriseList({
                beginCreateTime: this.projectTime ? this.beginCreateTime : resStart,
                endCreateTime: this.projectTime ? this.endCreateTime : resEnd,
                pageNum: this.enterpriseaPageNum,
                pageSize: this.enterpriseaPageSize,
                searchText: this.searchProjectText
            })
            this.tableEnterpriseData = result.list
            this.enterpriseaTotal = result.total
        },
        async projectHandleChange () {
            // if (!this.projectTime) {
            //     ElMessage({ message: '请选择时间', type: 'warning' })
            //     return
            // }
            var resEnd = new Date().toLocaleDateString().split('/').join('-') // 只包含日期
            console.log(resEnd, '只包含日期')
            var resStart = resEnd.split('-')[0] + '-' + resEnd.split('-')[1] + '-01'
            console.log(resStart, '只包含日期')
            const result = await getSignProjectList({
                beginCreateTime: this.projectTime ? this.beginCreateTime : resStart,
                endCreateTime: this.projectTime ? this.endCreateTime : resEnd,
                pageNum: this.projectPageNum,
                pageSize: this.projectPageSize,
                searchText: this.searchProjectText
            })
            this.projectTotal = result.total
            this.tableProjectData = result.list
        },
        async getAloneQuery () {
            // if (!this.projectTime) {
            //     ElMessage({ message: '请选择时间', type: 'warning' })
            //     return
            // }
            var resEnd = new Date().toLocaleDateString().split('/').join('-') // 只包含日期
            console.log(resEnd, '只包含日期')
            var resStart = resEnd.split('-')[0] + '-' + resEnd.split('-')[1] + '-01'
            console.log(resStart, '只包含日期')
            const result = await getSignAloneList({
                beginCreateTime: this.projectTime ? this.beginCreateTime : resStart,
                endCreateTime: this.projectTime ? this.endCreateTime : resEnd,
                pageNum: this.alonePageNum,
                pageSize: this.alonePageSize,
                searchText: this.searchProjectText
            })
            this.aloneTotal = result.total
            this.aloneList = result.list
        },
        choiceTimeSection () {
            console.log('清空了吗')
            // console.log(this.projectTime, '清空了吗')
            if (this.projectTime) {
                const startTime = new Date(this.projectTime[0]).getTime()
                const endTime = new Date(this.projectTime[1]).getTime()
                if (endTime - startTime > 2592000000) {
                    ElMessage({
                        message: '最多只能选择31天',
                        type: 'warning'
                    })
                    this.projectTime = ''
                    return
                }
                this.beginCreateTime = dateFormat(
                    'YY-mm-dd',
                    new Date(this.projectTime[0])
                )
                this.endCreateTime = dateFormat(
                    'YY-mm-dd',
                    new Date(this.projectTime[1])
                )
                console.log(this.beginCreateTime, '开始的时间', this.endCreateTime, '结束的实际')
            }
        },
        projectSizeChange () {
            this.projectPageNum = 1
            this.projectHandleChange()
        },
        enterpriseSizeChange () {
            this.enterpriseaPageNum = 1
            this.companyHandleChange()
        },
        // 个人换条数
        aloneSizeChange () {
            this.alonePageNum = 1
            this.getAloneQuery()
        },
        // 点击项目进入详情
        onGoProjectDetail (id: string, title: string, name: string) {
            // if (!this.projectTime) {
            //     ElMessage({
            //         message: '请选择时间',
            //         type: 'warning'
            //     })
            //     return
            // }
            if (this.projectTime) {
                this.$router.push({
                    path:
                '/sign/detail/' +
                this.beginCreateTime +
                '/' +
                this.endCreateTime +
                '/' +
                encodeURIComponent(title) +
                '/' +
                encodeURIComponent(name) +
                '/' +
                id +
                '/' +
                '0'
                })
            } else {
                this.$router.push({
                    path:
                '/sign/detail/' +
                this.defaultStart +
                '/' +
                this.defaultEnd +
                '/' +
                encodeURIComponent(title) +
                '/' +
                encodeURIComponent(name) +
                '/' +
                id +
                '/' +
                '0'
                })
            }
        },
        onGoCompanyDetail (id: string, title: string, name: string) {
            // if (!this.projectTime) {
            //     ElMessage({
            //         message: '请选择时间',
            //         type: 'warning'
            //     })
            //     return
            // }
            if (!this.projectTime) {
                this.$router.push({
                    path:
                '/sign/detail/' +
                this.defaultStart +
                '/' +
                this.defaultEnd +
                '/' +
                title +
                '/' +
                name +
                '/' +
                id +
                '/' +
                '1'
                })
            } else {
                this.$router.push({
                    path:
                '/sign/detail/' +
                this.beginCreateTime +
                '/' +
                this.endCreateTime +
                '/' +
                title +
                '/' +
                name +
                '/' +
                id +
                '/' +
                '1'
                })
            }
        },
        onGoAloneDetail (id: string, title: string, name: string) {
            // if (!this.projectTime) {
            //     ElMessage({
            //         message: '请选择时间',
            //         type: 'warning'
            //     })
            //     return
            // }
            if (this.projectTime) {
                this.$router.push({
                    path:
                '/sign/detail/' +
                this.beginCreateTime +
                '/' +
                this.endCreateTime +
                '/' +
                title +
                '/' +
                name +
                '/' +
                id +
                '/' +
                '2'
                })
            } else {
                this.$router.push({
                    path:
                '/sign/detail/' +
                this.defaultStart +
                '/' +
                this.defaultEnd +
                '/' +
                title +
                '/' +
                name +
                '/' +
                id +
                '/' +
                '2'
                })
            }
        },
        async handleClick (event: { props: { name: string } }) {
            console.log(event.props.name, '切换的内容是什么')
            this.isEnterpriseProjectType = event.props.name
            if (event.props.name === 'all') {
                const result = await getSignTopNum({
                    queryType: 0,
                    beginCreateTime: this.beginCreateTime ? this.beginCreateTime : this.defaultStart,
                    endCreateTime: this.endCreateTime ? this.endCreateTime : this.defaultEnd
                })
                this.peopleCount = result.peopleCount
                this.projectCount = result.projectCount
            }
            if (event.props.name === 'not') {
                const result = await getSignTopNum({
                    queryType: 1,
                    beginCreateTime: this.beginCreateTime ? this.beginCreateTime : this.defaultStart,
                    endCreateTime: this.endCreateTime ? this.endCreateTime : this.defaultEnd
                })
                this.servePeopleCount = result.peopleCount
                this.serveCompanyCount = result.serveCompanyCount
                await this.companyHandleChange()
            }
            if (event.props.name === 'alone') {
                await this.getAloneQuery()
            }
        },
        async projectSearch () {
            if (this.isEnterpriseProjectType === 'all') {
                this.projectPageNum = 1
                this.projectHandleChange()
                const result = await getSignTopNum({
                    queryType: 0,
                    beginCreateTime: this.beginCreateTime ? this.beginCreateTime : this.defaultStart,
                    endCreateTime: this.endCreateTime ? this.endCreateTime : this.defaultEnd
                })
                this.peopleCount = result.peopleCount
                this.projectCount = result.projectCount
            } else if (this.isEnterpriseProjectType === 'not') {
                this.enterpriseaPageNum = 1
                this.companyHandleChange()
                const result = await getSignTopNum({
                    queryType: 1,
                    beginCreateTime: this.beginCreateTime ? this.beginCreateTime : this.defaultStart,
                    endCreateTime: this.endCreateTime ? this.endCreateTime : this.defaultEnd
                })
                this.servePeopleCount = result.peopleCount
                this.serveCompanyCount = result.serveCompanyCount
            } else {
                this.alonePageNum = 1
                this.getAloneQuery()
            }
            // this.projectHandleChange()
        },
        onProjectReset () {
            this.searchProjectText = ''
            // this.projectSearch()
            this.tableProjectData = []
            this.projectTime = ''
        },
        enterpriseSearch () {
            this.enterpriseaPageNum = 1
            this.companyHandleChange()
        },
        onEnterpriseReset () {
            this.searchEnterpriseaText = ''
            // this.enterpriseSearch()
            this.tableEnterpriseData = []
            this.enterpriseTime = ''
        }
    }
})
